import axios from "axios";
import { Message } from "element-ui";
import { mdApiBase } from "./config.service";

// import { saveAs } from 'file-saver'
// import { tansParams, blobValidate, errorCode } from "@/utils/index"
// import localStorageService from './localStorage.service.js'
/**
 * 超时
 */
axios.defaults.timeout = 1000000000;
axios.defaults.baseURL = `${mdApiBase}`;
axios.defaults.retry = 10;
axios.defaults.retryDelay = 1000;
/**
 * 请求拦截
 */
axios.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("Authorization")) {
      config.headers.authorization = localStorage.getItem("Authorization");
    }
    config.headers["J-Prefer-Host"] = "klyxkj.skyelearning.cn";
    return config;
  },
  (error) => {
    // console.log(error);
    return Promise.reject(error);
  }
);
/**
 * 响应拦截
 */
axios.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (response.status === 200) {
      if (
        data.code === 200 ||
        data.code === 40003 ||
        response.config.responseType === "blob"
      ) {
        return data;
      } else if (data.code === 401) {
        localStorage.clear();

        // Message.error('登陆超时,即将跳转登录页面...')
        // window.location.href = "/login"
        //  Message.error('登录超时')
        // setTimeout(() => {
        //   window.location.reload()
        // }, 1000);
        return data;
      } else {
        Message.error(data.msg || "服务器异常");
        return Promise.reject(response);
      }
    } else {
      Message.error("服务器异常");
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response) {
      let { data, status } = error.response;
      ResponseStatus(status, data.msg);
    }
    return Promise.reject(error);
  }
);
/**
 * 响应状态拦截
 */
let ResponseStatus = (status, message) => {
  if (status === 401) {
    localStorage.clear();
    // Message.error('登陆超时,即将跳转登录页面...')
    // window.location.href = '/login'
  } else {
    Message.error(message);
  }
};
let HttpService = axios;
// export function download (url, params, filename, config) {
//   const downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
//   return HttpService.post(url, params, {
//     transformRequest: [(params) => { return tansParams(params) }],
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//     responseType: 'blob',
//     ...config
//   }).then(async (data) => {
//     console.log(data)
//     const isBlob = blobValidate(data);
//     if (isBlob) {
//       const blob = new Blob([data])
//       saveAs(blob, filename)
//     } else {
//       const resText = await data.text();
//       const rspObj = JSON.parse(resText);
//       const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
//       Message.error(errMsg);
//     }
//     downloadLoadingInstance.close();
//   }).catch((r) => {
//     console.error(r)
//     Message.error('下载文件出现错误，请联系管理员！')
//     downloadLoadingInstance.close();
//   })
// }
// export function zip (url, name) {
//   // let url1 = `${mdApiBase}` + url
//   HttpService({
//     method: 'post',
//     url: url,
//     responseType: 'blob',
//     headers: { 'Authorization': 'Bearer ' + localStorage.getItem('Authorization') }
//   }).then((res) => {
//     const isBlob = blobValidate(res);
//     if (isBlob) {
//       const blob = new Blob([res], { type: 'application/zip' })
//       this.saveAs(blob, name)
//     } else {
//       Message.error(res);
//     }
//   })
// }
export default HttpService;
